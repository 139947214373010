import React from "react";

class Home extends React.Component {
  render() {
    return (
      <div>

      </div>
    )
  }
}

export default Home;
