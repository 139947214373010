import React from "react";

class AboutUs extends React.Component {
  render() {
    return (
      <div>
        <h3>We a family owned business that currently is providing Mini Excavator Services.</h3>
      </div>
    )
  }
}

export default AboutUs;
